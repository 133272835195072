import { FormatResponse } from 'seed-core';

import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { YorN } from '@/types/common/common.type';
import type { Pagination } from '@/types/common/pagination.type';
import type { ProductPageClonedType } from '@/types/product-page/product-page-model.type';
import type { ProductPageRequest } from '@/types/product-page/product-page-request.type';
import type {
  CreateProductPageResponse,
  CreateSimpleProductPageResponse,
  ProductPageDetailResponse,
  ProductPageResponse,
  ProductPageSelectedNationsResponse,
  ProductPageSimpleResponse
} from '@/types/product-page/product-page-response.type';
import { camelToSnake } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+product+page+%7C+search+by+product+no
export const fetchProductPageInfoApi = async (params?: {
  productNo?: number;
  page?: number;
  size?: number;
  sortBy?: string;
  direction?: string;
}): Promise<Pagination<ProductPageResponse[]> | undefined> => {
  const { data } = await useRequest<Pagination<ProductPageResponse[]>>(
    `${apiUrl}/product/page/search`,
    {
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+get+page+for+launch
export const fetchProductPageForLaunchApi = async (params: {
  productNo?: number;
  page?: number;
  size?: number;
  sortBy?: string;
}): Promise<Pagination<ProductPageSimpleResponse[]> | undefined> => {
  const { data } = await useRequest<Pagination<ProductPageSimpleResponse[]>>(
    `${apiUrl}/product/${params.productNo}/page-launch`,
    {
      params: camelToSnake(params)
    }
  );
  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+create+by+product+no
export const createProductPageApi = async (
  request: ProductPageRequest
): Promise<CreateProductPageResponse | undefined> => {
  const { data } = await useRequest<CreateProductPageResponse>(`${apiUrl}/product/page`, {
    method: ApiMethod.Post,
    data: request,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+get+detail+by+page+id
export const fetchProductPageDetailApi = async (
  pageId: string
): Promise<ProductPageDetailResponse | undefined> => {
  const { data } = await useRequest<ProductPageDetailResponse>(`${apiUrl}/product/page/${pageId}`);

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+delete+by+page+id
export const deleteProductPageApi = async (pageId: string): Promise<null | undefined> => {
  const { data } = await useRequest<null>(`${apiUrl}/product/page/${pageId}`, {
    method: ApiMethod.Delete,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+modify+exhibition+by+page+id
export const modifyProductPageDisplayApi = async (
  pageId: string,
  displayYn: YorN
): Promise<null | undefined> => {
  const { data } = await useRequest<null>(
    `${apiUrl}/product/page/${pageId}/${displayYn}`,
    {
      method: ApiMethod.Patch,
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+modify+page+detail+by+page+id
export const modifyProductPageDetailApi = async (
  pageId: string,
  request: ProductPageRequest
): Promise<null | undefined> => {
  const { data } = await useRequest<null>(`${apiUrl}/product/page/${pageId}/detail`, {
    method: ApiMethod.Put,
    data: request,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+modify+page+name+by+page+id
export const modifyProductPageNameApi = async (
  pageName: string,
  pageId: string
): Promise<null | undefined> => {
  const { data } = await useRequest<null>(`${apiUrl}/product/page/${pageId}`, {
    method: ApiMethod.Put,
    data: camelToSnake({ pageName }),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+clone+by+page+id
export const duplicateProductPageApi = async (params: {
  pageId: string;
  pageName: string;
  productNoList: number[];
}): Promise<{ clones?: ProductPageClonedType[] } | undefined> => {
  const { data } = await useRequest<{ clones?: ProductPageClonedType[] }>(
    `${apiUrl}/product/page/clone`,
    {
      method: ApiMethod.Put,
      data: camelToSnake(params),
      showCommonError: true,
      cancelAfter: 30000
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+create+simple+page+by+product+no
export const createSimpleProductPageApi = async (request: {
  productNo: number;
  pageName: string;
}): Promise<CreateSimpleProductPageResponse | undefined> => {
  const { data } = await useRequest<CreateSimpleProductPageResponse>(
    `${apiUrl}/product/page/simple`,
    {
      method: ApiMethod.Post,
      data: camelToSnake(request),
      showCommonError: false
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+sync+page+preview+by+page+id
export const syncProductPagePreviewApi = async (
  pageId: string,
  request: ProductPageRequest,
  showLoading?: boolean,
  defaultLang?: string
): Promise<{ previewKey: string } | undefined> => {
  const { data } = await useRequest<{ previewKey: string }>(
    `${apiUrl}/product/page/preview/${pageId}`,
    {
      method: ApiMethod.Put,
      data: request,
      showCommonError: false,
      showLoading: showLoading || false,
      headers: {
        'X-Lang': defaultLang
      }
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+generate+preview+key
export const generateProductPagePreviewKeyApi = async (
  productNo: number,
  pageId: string,
  defaultLang?: string
): Promise<{ previewKey: string } | undefined> => {
  const { data } = await useRequest<{ previewKey: string }>(
    `${apiUrl}/product/page/${productNo}/preview/${pageId}`,
    {
      showCommonError: false,
      headers: {
        'X-Lang': defaultLang
      }
    }
  );
  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+search+product+page+selected+nations+by+product+nos
export const fetchProductPageSelectedNations = async (
  productNos: number[]
): Promise<ProductPageSelectedNationsResponse | undefined> => {
  const { data } = await useRequest<ProductPageSelectedNationsResponse>(
    `${apiUrl}/product/page/selected-nations`,
    {
      params: camelToSnake({ productNos: productNos.join(',') })
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+product+page+%7C+suspend+by+page+id
export const suspendProductPageApi = async (
  pageId: string,
  suspendYn: YorN
): Promise<{suspended: boolean} | undefined> => {
  const { data } = await useRequest<{suspended: boolean}>(
    `${apiUrl}/product/page/${pageId}/suspend/${suspendYn}`,
    {
      method: ApiMethod.Put,
      formatResponse: FormatResponse.Custom,
      showCommonError: false
    }
  );

  return data;
};
